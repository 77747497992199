import React from "react"

const Logo = ({ color = "#1a1a1a", id = "athena-logo" }) => {
  return (
    <>
      <svg
           width="110px"
           height="60px"
           version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 516.8 281.08"
           aria-labelledby={id}
           role="img"
      >
        <defs>
          <style>
          </style>
        </defs>
        <title id={id}>Athena Jones</title>
        <g>
          <g fill={color}
             fillRule="nonzero">
            <g>
              <path className="cls-2"
                    d="M35.65,248.38c0,4.18-.68,7.48-2.03,9.81-1.34,2.31-3.19,3.93-5.49,4.8-2.34.89-5.34,1.33-8.91,1.33H5.41v14.83h13.81c7.27,0,13.4-1.03,18.23-3.07,4.85-2.05,8.6-5.39,11.14-9.92,2.53-4.52,3.81-10.56,3.81-17.94v-89.82h-16.75v89.98Z"/>
              <path className="cls-2"
                    d="M127.42,193.1c-6.22-3.81-13.61-5.74-21.96-5.74s-15.79,1.93-22.11,5.73c-6.33,3.81-11.25,9.3-14.62,16.32-3.37,7.01-5.08,15.38-5.08,24.88s1.74,17.88,5.16,24.88c3.43,7.02,8.35,12.48,14.62,16.24,6.27,3.75,13.63,5.65,21.87,5.65s15.6-1.9,21.87-5.65,11.19-9.21,14.62-16.24c3.42-7,5.16-15.38,5.16-24.88s-1.71-17.88-5.08-24.88c-3.38-7.02-8.24-12.51-14.46-16.32ZM130.69,234.3c0,7.06-1.04,13.15-3.09,18.1-2.04,4.92-4.98,8.69-8.74,11.19s-8.32,3.78-13.55,3.78-9.66-1.3-13.47-3.86c-3.81-2.56-6.78-6.32-8.82-11.19-2.05-4.89-3.09-10.95-3.09-18.02s1.01-13.15,3.01-18.1c1.99-4.92,4.93-8.71,8.74-11.27,3.81-2.56,8.4-3.86,13.63-3.86s9.95,1.3,13.71,3.85c3.76,2.56,6.67,6.35,8.66,11.27,2,4.95,3.01,11.04,3.01,18.1Z"/>
              <path className="cls-2"
                    d="M219.36,191.42c-4.92-2.76-10.19-4.05-16.58-4.05-6.75,0-12.68,1.44-17.62,4.29-4.69,2.7-8.23,6.54-10.8,11.71h-.48v-14.08h-16.11v89.87h16.11v-51.73c0-4.83.98-9.24,2.92-13.11,1.93-3.86,4.74-6.96,8.34-9.21,3.6-2.24,7.92-3.38,12.84-3.38,6.47,0,11.51,2.06,15,6.11,3.5,4.07,5.27,9.96,5.27,17.52v53.81h16.27v-55.58c0-7.91-1.33-14.64-3.96-20-2.64-5.38-6.4-9.47-11.19-12.16Z"/>
              <path className="cls-2"
                    d="M305.99,193.11c-5.75-3.81-12.73-5.74-20.77-5.74-8.56,0-16,1.93-22.12,5.74-6.11,3.81-10.85,9.27-14.06,16.24-3.21,6.95-4.83,15.29-4.83,24.8s1.54,17.39,4.59,24.39c3.06,7.03,7.73,12.6,13.9,16.57,6.16,3.97,13.8,5.98,22.68,5.98,6.52,0,12.47-1.17,17.67-3.47,5.21-2.31,9.56-5.71,12.95-10.11,3.57-4.64,5.77-10.08,6.95-17.13l.07-.44h-15.79l-.06.3c-.78,3.98-2.79,7.57-4.4,9.6-1.82,2.29-4.14,4.05-6.9,5.25-2.77,1.2-6.13,1.81-10.01,1.81-5.56,0-10.29-1.09-14.05-3.23-3.75-2.14-6.64-5.34-8.57-9.51-1.9-4.11-3.09-9.21-3.54-15.15h63.3l.03-.34c.11-1.29.19-2.61.24-3.94.05-1.35.08-2.56.08-3.61,0-8.23-1.46-15.73-4.35-22.31-2.89-6.59-7.27-11.87-13.02-15.68ZM272.44,203.03c3.45-1.83,7.65-2.76,12.46-2.76s8.78,1.08,12.12,3.22c3.33,2.14,5.9,5.18,7.62,9.04,1.69,3.79,2.67,8.34,2.91,13.55h-47.67c.65-5.82,2.05-10.69,4.15-14.48,2.14-3.86,4.97-6.74,8.41-8.57Z"/>
              <path className="cls-2"
                    d="M399.89,239.61h0c-2.22-3.35-5.75-6.11-10.48-8.2-4.7-2.08-11.19-3.83-19.29-5.22-5.72-.95-10.11-1.97-13.06-3.02-2.91-1.03-5.03-2.34-6.32-3.88-1.27-1.52-1.91-3.64-1.91-6.31,0-2.46.68-4.65,2.01-6.49,1.33-1.84,3.4-3.28,6.14-4.26,2.77-.99,6.15-1.5,10.03-1.5,6.07,0,10.83,1.37,14.16,4.07,3.31,2.69,5.15,6.47,5.46,11.23l.02.35h15.79s-.02-.39-.02-.39c-.32-5.89-1.93-11.06-4.78-15.36-2.86-4.3-6.92-7.63-12.08-9.88-5.14-2.25-11.39-3.39-18.55-3.39-7.17,0-13.38,1.14-18.47,3.39-5.11,2.25-9.09,5.5-11.84,9.64-2.75,4.14-4.14,9.02-4.14,14.5,0,4.85,1.07,8.92,3.18,12.11,2.11,3.19,5.5,5.86,10.08,7.96,4.54,2.08,10.69,3.78,18.25,5.06,6.15,1.17,10.84,2.32,13.94,3.42,3.07,1.09,5.3,2.5,6.63,4.19,1.33,1.68,2,3.94,2,6.72,0,4.21-1.7,7.52-5.04,9.83-3.39,2.34-8.24,3.53-14.43,3.53-6.91,0-12.29-1.66-15.99-4.94-3.44-3.04-5.38-7.29-5.79-12.61l-.03-.35h-16.27s.03.4.03.4c.73,9.64,4.3,17.22,10.61,22.54,6.54,5.52,15.77,8.31,27.44,8.31,7.69,0,14.31-1.19,19.67-3.55,5.38-2.36,9.5-5.71,12.24-9.96,2.75-4.25,4.14-9.28,4.14-14.98,0-5.26-1.12-9.63-3.34-12.98Z"/>
            </g>
            <path className="cls-2"
                  d="M100.02,110.62L80.36,0l-4.12,6.31L16.13,110.82c-6.13,10.53-9.36,13.41-15.04,13.41h-.31l-.78,4.27h36.43l.97-4.25-.45-.02c-5.85-.24-9.24-1.54-10.66-4.08-1.78-3.19-.5-8.58,3.93-16.48l5.97-10.69h33.54l2.83,17.76c.84,5.47.43,8.94-1.26,10.92-1.54,1.8-4.26,2.57-9.09,2.57h-.3l-.97,4.27h47.02l.78-4.27h-.45c-5.11,0-6.49-3.23-8.27-13.61ZM68.49,85.19h-28.04s21.72-38.98,21.72-38.98l6.32,38.98Z"/>
            <path className="cls-2"
                  d="M161.23,101.98l-3.53-1.37-.15.32c-4.34,9.46-8.6,13.86-13.42,13.86-1.62,0-2.88-.55-3.73-1.63-1.63-2.06-1.82-6.04-.54-11.51l13.05-54.43h18.57l1.76-8.43h-18.23s7.04-29.28,7.04-29.28h-5.36l-.1.22c-9.21,19.87-25.32,31.57-36.38,34.03l-.23.05-.8,3.41h7.67l-13.32,53.14c-2.35,9.49-1.38,17.35,2.82,22.72,3.63,4.64,9.52,7.19,16.58,7.19,13.28,0,22.5-9.14,28.2-27.95l.1-.33Z"/>
            <path className="cls-2"
                  d="M239.09,36.87c-9.5,0-18.81,5.51-31.94,18.95L221.07,0l-38.94,14.73-.97,4.26.55-.09c3.41-.58,5.42-.72,6.29.22,1.08,1.16.56,4.4-.63,9.24l-24.96,99.68-.12.47h26.69l15.57-62.11c6.91-7.39,13.89-11.97,18.22-11.97,1.42,0,2.47.43,3.12,1.28,1.09,1.42,1.16,4.01.2,7.69l-14.08,51.37c-1.46,5.75-1.23,9.75.7,12.24,1.54,1.98,4.1,2.95,7.82,2.95,9.03,0,19.07-4.39,29.84-13.04l.22-.18-1.58-3.55-.37.25c-2.57,1.71-5.11,2.66-7.15,2.66-.99,0-1.67-.26-2.07-.79-.63-.83-.62-2.4.03-4.66l12.96-46.89c2.3-9,1.44-16.96-2.36-21.85-2.55-3.28-6.34-5.02-10.97-5.02Z"/>
            <path className="cls-2"
                  d="M325.36,93.06c-6.29,11.28-14.42,17.25-23.51,17.25-7.57,0-16.58-3.68-16.58-21.23v-2.23c31.82-4.16,48.64-14.12,48.64-28.81,0-11.99-11.19-20.7-26.62-20.7-27.18,0-50.14,26.33-50.14,57.5,0,20.57,12.92,34.94,31.42,34.94,12.67,0,30.04-6.04,40.83-34.8l.12-.32-3.99-1.9-.17.31ZM303.29,44.34c3.91,0,6.34,4.15,6.34,10.83,0,13.92-6.74,20.99-24.01,25.12,1.94-22.22,10.64-35.94,17.66-35.94Z"/>
            <path className="cls-2"
                  d="M408.29,36.87c-9.66,0-19.07,5.6-32.26,19.26l4.79-19.32-38.44,13.91-1.17,4.3.6-.12c2.87-.59,4.97-.78,5.9.18,1.09,1.14.93,3.96-.58,10.08l-15.8,63.35h26.53l15.57-61.95c7.12-7.6,13.99-12.13,18.38-12.13,1.5,0,2.61.44,3.28,1.32,1.09,1.41,1.16,3.99.2,7.65l-14.08,51.36c-1.54,5.69-1.34,9.68.59,12.21,1.52,1.98,4.13,2.98,7.77,2.98,9.14,0,19.23-4.39,30-13.04l.22-.18-1.58-3.55-.37.25c-2.5,1.66-5.17,2.66-7.15,2.66-.99,0-1.67-.26-2.07-.79-.63-.83-.62-2.4.03-4.66l12.96-46.89c2.3-9,1.44-16.96-2.36-21.85-2.55-3.28-6.34-5.02-10.97-5.02Z"/>
            <path className="cls-2"
                  d="M516.69,39.26l.12-.47h-23.32l-1.46,4.68c-3.1-3.95-7.96-6.12-13.78-6.12-24.72,0-52.38,27.33-52.38,63.9,0,16.8,8.91,28.54,21.66,28.54,8.79,0,17-4.8,24.42-14.26-.57,5.43.16,9.24,2.24,11.63,1.61,1.85,4.05,2.79,7.26,2.79,10.62,0,21.29-7.44,28.39-13.04l.23-.18-1.58-3.55-.37.24c-2.73,1.76-5.19,2.66-7.32,2.66-1,0-1.66-.23-2.02-.72-.59-.78-.54-2.33.15-4.74l17.76-71.36ZM487.45,51.67l-13.07,52.3c-4.39,6.49-8.24,9.38-12.45,9.38-5.43,0-8.42-5.44-8.42-15.31,0-13.14,3.08-26.79,8.44-37.45,5.02-9.98,11.29-15.93,16.79-15.93,3.9,0,6.83,2.36,8.73,7.01Z"/>
          </g>
        </g>
      </svg>
    </>
  )
}

export default Logo
