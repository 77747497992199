import React from "react"

const Logo = ({ color = "#1A1C2C", id = "zj-logo", width = 90 }) => {
  return (
    <>
      <svg
           xmlns="http://www.w3.org/2000/svg"
           width={`${width}px`}
           aria-labelledby={id}
           version="1.1"
           role="img"
           viewBox="0 0 397.83 273.42">
        <defs>
          <style>
          </style>
        </defs>
        <title id={id}>Zeus Jones</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color}
             fillRule="nonzero">
            <g>
              <path className="cls-2"
                    d="M294.8,30.35c-.74,2.06-1.96,3.02-3.85,3.02-.8,0-1.99-.49-3.5-1.12-3.17-1.31-7.97-3.3-15.04-3.3-15,0-27.66,11.41-27.66,24.92,0,10.88,8.78,18.12,17.26,25.11,7.86,6.48,15.27,12.59,15.27,21.09s-5.74,16.78-18.34,16.78c-6.98,0-11.68-1.41-15.25-4.57-1.59-1.41-2.36-3.51-1.95-5.34.76-3.42,1.38-7.32-.78-10.33-.98-1.36-2.43-2.23-4.1-2.46-1.69-.23-3.42.24-4.73,1.29-2.59,1.94-3.47,5.67-3.04,8.94-5.37,5.68-9.5,8.56-12.29,8.56-.78,0-1.31-.19-1.61-.58-.5-.65-.5-1.97,0-3.92l20.29-77.57h-12.98l-16.42,62.07c-11.23,12.63-22.63,20.16-30.49,20.16-2.78,0-4.81-.81-6.02-2.4-1.84-2.43-1.92-6.74-.22-12.84l18.04-69.17-24.45,9.97-.83,3.82.58-.14c3.83-.9,5.73-.92,6.55-.07,1.23,1.26.35,4.99-1.23,10.99l-11.92,44.4c-2.23,8.45-1.57,15.57,1.87,20.04,2.49,3.24,6.42,4.95,11.35,4.96h.02c12.44,0,25.37-10.72,34.34-20.18l-2.08,7.7c-1.04,4.47-.76,7.76.84,9.78,1.17,1.47,3,2.22,5.46,2.22,3.84,0,13.32-1.34,22.95-12.89.88,1.52,2.04,2.92,3.4,4.1,2.78,2.41,5.84,4.37,9.1,5.83,5.12,2.29,10.35,3.44,15.54,3.44,20.38,0,31.04-13.18,31.04-26.19,0-12.25-10.15-20.5-19.1-27.78-7.27-5.91-14.14-11.5-14.14-18.17,0-10.35,7.56-15.77,15.02-15.77,6.02,0,10.54,1.57,13.43,4.66,3.16,3.38,4.52,8.71,4.04,15.86l-.03.4h4.55l5.41-25.58h-4.23l-.09.25Z"/>
              <path className="cls-2"
                    d="M146.25,89.05c-6.56,15.13-16.59,23.46-28.25,23.46-12.91,0-20.62-9.81-20.62-26.23,0-1.64,0-3.33.14-4.86,39.06-5.02,58.05-16.11,58.05-33.92,0-4.93-2.01-9.51-5.66-12.89-4-3.7-9.6-5.66-16.21-5.66-12.06,0-24.6,6.53-34.42,17.92-10.09,11.71-15.88,27.19-15.88,42.49,0,.14,0,.28,0,.42-6.1,21.66-10.45,24.78-29.65,24.78H17.22L101.56,4.28l.79-4.28H27.48l-6.4,30.51h4.91l.07-.28c6.18-23.09,10.47-24.04,25.88-24.04h33.2S.97,116.47.97,116.47l-.97,4.28h81.9l3.8-17.68c4.36,11.99,14.61,19.6,27.83,19.6,17.23,0,30.21-11.33,36.56-31.9l.1-.31-3.79-1.76-.15.35ZM98.28,75.61c3.82-24.47,20.14-40.87,33.11-40.87,8.01,0,10.86,6.6,10.86,12.77,0,14.25-14.38,23.44-43.97,28.11Z"/>
            </g>
            <g>
              <path className="cls-2"
                    d="M30.24,240.73c0,4.18-.68,7.48-2.03,9.81-1.34,2.31-3.19,3.93-5.49,4.8-2.34.89-5.34,1.33-8.91,1.33H0v14.83h13.81c7.27,0,13.4-1.03,18.23-3.07,4.85-2.05,8.6-5.39,11.14-9.92,2.53-4.52,3.81-10.56,3.81-17.94v-89.82h-16.75v89.98Z"/>
              <path className="cls-2"
                    d="M122.01,185.45c-6.22-3.81-13.61-5.74-21.96-5.74s-15.79,1.93-22.11,5.73c-6.33,3.81-11.25,9.3-14.62,16.32-3.37,7.01-5.08,15.38-5.08,24.88s1.74,17.88,5.16,24.88c3.43,7.02,8.35,12.48,14.62,16.24,6.27,3.75,13.63,5.65,21.87,5.65s15.6-1.9,21.87-5.65,11.19-9.21,14.62-16.24c3.42-7,5.16-15.38,5.16-24.88s-1.71-17.88-5.08-24.88c-3.38-7.02-8.24-12.51-14.46-16.32ZM125.28,226.65c0,7.06-1.04,13.15-3.09,18.1-2.04,4.92-4.98,8.69-8.74,11.19s-8.32,3.78-13.55,3.78-9.66-1.3-13.47-3.86c-3.81-2.56-6.78-6.32-8.82-11.19-2.05-4.89-3.09-10.95-3.09-18.02s1.01-13.15,3.01-18.1c1.99-4.92,4.93-8.71,8.74-11.27,3.81-2.56,8.4-3.86,13.63-3.86s9.95,1.3,13.71,3.85c3.76,2.56,6.67,6.35,8.66,11.27,2,4.95,3.01,11.04,3.01,18.1Z"/>
              <path className="cls-2"
                    d="M213.96,183.76c-4.92-2.76-10.19-4.05-16.58-4.05-6.75,0-12.68,1.44-17.62,4.29-4.69,2.7-8.23,6.54-10.8,11.71h-.48v-14.08h-16.11v89.87h16.11v-51.73c0-4.83.98-9.24,2.92-13.11,1.93-3.86,4.74-6.96,8.34-9.21,3.6-2.24,7.92-3.38,12.84-3.38,6.47,0,11.51,2.06,15,6.11,3.5,4.07,5.27,9.96,5.27,17.52v53.81h16.27v-55.58c0-7.91-1.33-14.64-3.96-20-2.64-5.38-6.4-9.47-11.19-12.16Z"/>
              <path className="cls-2"
                    d="M300.58,185.46c-5.75-3.81-12.73-5.74-20.77-5.74-8.56,0-16,1.93-22.12,5.74-6.11,3.81-10.85,9.27-14.06,16.24-3.21,6.95-4.83,15.29-4.83,24.8s1.54,17.39,4.59,24.39c3.06,7.03,7.73,12.6,13.9,16.57,6.16,3.97,13.8,5.98,22.68,5.98,6.52,0,12.47-1.17,17.67-3.47,5.21-2.31,9.56-5.71,12.95-10.11,3.57-4.64,5.77-10.08,6.95-17.13l.07-.44h-15.79l-.06.3c-.78,3.98-2.79,7.57-4.4,9.6-1.82,2.29-4.14,4.05-6.9,5.25-2.77,1.2-6.13,1.81-10.01,1.81-5.56,0-10.29-1.09-14.05-3.23-3.75-2.14-6.64-5.34-8.57-9.51-1.9-4.11-3.09-9.21-3.54-15.15h63.3l.03-.34c.11-1.29.19-2.61.24-3.94.05-1.35.08-2.56.08-3.61,0-8.23-1.46-15.73-4.35-22.31-2.89-6.59-7.27-11.87-13.02-15.68ZM267.03,195.38c3.45-1.83,7.65-2.76,12.46-2.76s8.78,1.08,12.12,3.22c3.33,2.14,5.9,5.18,7.62,9.04,1.69,3.79,2.67,8.34,2.91,13.55h-47.67c.65-5.82,2.05-10.69,4.15-14.48,2.14-3.86,4.97-6.74,8.41-8.57Z"/>
              <path className="cls-2"
                    d="M394.48,231.95h0c-2.22-3.35-5.75-6.11-10.48-8.2-4.7-2.08-11.19-3.83-19.29-5.22-5.72-.95-10.11-1.97-13.06-3.02-2.91-1.03-5.03-2.34-6.32-3.88-1.27-1.52-1.91-3.64-1.91-6.31,0-2.46.68-4.65,2.01-6.49,1.33-1.84,3.4-3.28,6.14-4.26,2.77-.99,6.15-1.5,10.03-1.5,6.07,0,10.83,1.37,14.16,4.07,3.31,2.69,5.15,6.47,5.46,11.23l.02.35h15.79l-.02-.39c-.32-5.89-1.93-11.06-4.78-15.36-2.86-4.3-6.92-7.63-12.08-9.88-5.14-2.25-11.39-3.39-18.55-3.39s-13.38,1.14-18.47,3.39c-5.11,2.25-9.09,5.5-11.84,9.64-2.75,4.14-4.14,9.02-4.14,14.5,0,4.85,1.07,8.92,3.18,12.11,2.11,3.19,5.5,5.86,10.08,7.96,4.54,2.08,10.69,3.78,18.25,5.06,6.15,1.17,10.84,2.32,13.94,3.42,3.07,1.09,5.3,2.5,6.63,4.19,1.33,1.68,2,3.94,2,6.72,0,4.21-1.7,7.52-5.04,9.83-3.39,2.34-8.24,3.53-14.43,3.53-6.91,0-12.29-1.66-15.99-4.94-3.44-3.04-5.38-7.29-5.79-12.61l-.03-.35h-16.27l.03.4c.73,9.64,4.3,17.22,10.61,22.54,6.54,5.52,15.77,8.31,27.44,8.31,7.69,0,14.31-1.19,19.67-3.55,5.38-2.36,9.5-5.71,12.24-9.96,2.75-4.25,4.14-9.28,4.14-14.98,0-5.26-1.12-9.63-3.34-12.98Z"/>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default Logo
